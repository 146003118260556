import { get } from 'bv-local-storage';
import { hasSportsbook, hasCasino } from 'global-config';

const CASINO_TAB = 1;
const SPORTS_TAB = 3;

export default () => {
  if (!hasCasino) return SPORTS_TAB;
  if (!hasSportsbook) return CASINO_TAB;

  return get('my_bets_last') || SPORTS_TAB;
};
