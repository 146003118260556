import { fetchOptions, fetchReportStatus } from '../api/app';
import { reportType } from '../helpers/export';

export const SET_CONFIG = 'accountHistory/app/SET_CONFIG';
export const RESET_CONFIG = 'accountHistory/app/RESET_CONFIG';
export const LOAD_OPTIONS = 'accountHistory/app/LOAD_OPTIONS';
export const SET_OPTIONS = 'accountHistory/app/SET_OPTIONS';
export const SET_REPORT_INFO = 'accountHistory/app/SET_REPORT_INFO';

export const setConfig = (pageSection, pageType) => ({
  type: SET_CONFIG,
  pageSection,
  pageType,
});

export const resetConfig = () => ({
  type: RESET_CONFIG,
});

const setOptions = (options) => ({
  type: SET_OPTIONS,
  options,
});

export const setReportInfo = (report) => ({
  type: SET_REPORT_INFO,
  reportType: report.type,
  reportUrl: report.reportDownloadUrl,
  reportId: report.reportId,
  reportStatus: report.status,
});

export const loadReportStatus = (type) => (dispatch) => (
  fetchReportStatus(type).then((data) => dispatch(setReportInfo(data)))
);

export const loadOptions = (section) => (dispatch) => (
  reportType[section]
    ? fetchOptions(reportType[section]).then((data) => dispatch(setOptions(data)))
    : undefined
);

export const initialState = {
  pageSection: null,
  pageType: null,
  options: {
    export: false,
  },
  reports: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        pageSection: action.pageSection,
        pageType: action.pageType,
      };
    case SET_OPTIONS:
      return {
        ...state,
        options: action.options,
      };
    case SET_REPORT_INFO:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportType]: {
            status: action.reportStatus,
            url: action.reportUrl,
            id: action.reportId,
          },
        },
      };
    case RESET_CONFIG:
      return initialState;
    default:
      return state;
  }
};
