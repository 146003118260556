import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { load } from 'AccountHistory/ducks/legs';
import LegList from './leg_list';

class LegListContainer extends Component {
  componentDidMount() {
    const { fetch, id } = this.props;

    fetch(id);
  }

  render() {
    return <LegList {...this.props} />;
  }
}

LegListContainer.propTypes = {
  fetch: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = (state, ownProps) => (
  state.accountHistoryLegs[ownProps.id]
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetch: () => dispatch(load(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LegListContainer);
