import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';
import { confirmStatus } from 'AccountHistory/helpers/export';

const tNamescape = 'transaction_history.export.confirm';

const ConfirmModal = ({ onClose, onConfirm, status }) => {
  const actions = useMemo(() => {
    if (status === confirmStatus.REQUIRED) {
      return [
        {
          id: 'no-btn',
          label: t(`${tNamescape}.false`),
          secondary: true,
          inverse: true,
          onClick: onClose,
        },
        {
          id: 'yes-btn',
          label: t(`${tNamescape}.true`),
          secondary: true,
          inverse: false,
          onClick: onConfirm,
        },
      ];
    }

    return [{
      id: 'close-btn',
      label: t('close'),
      secondary: status === confirmStatus.CONFIRMED,
      danger: status === confirmStatus.FAILED,
      inverse: true,
      onClick: onClose,
    }];
  }, [status]);

  const description = useMemo(() => {
    switch (status) {
      case confirmStatus.REQUIRED:
        return t(`${tNamescape}.body`);
      case confirmStatus.CONFIRMED:
        return t('transaction_history.export.processing');
      case confirmStatus.FAILED:
        return t('errors_something_wrong');
      default:
        return null;
    }
  }, [status]);

  if (status === confirmStatus.NONE) return null;

  return (
    <RenderInBody>
      <Modal
        info={status !== confirmStatus.FAILED}
        danger={status === confirmStatus.FAILED}
        actions={actions}
      >
        <div>
          <p>{description}</p>
        </div>
      </Modal>
    </RenderInBody>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(confirmStatus)).isRequired,
};

export default ConfirmModal;
