import { t } from 'bv-i18n';

const PendingPayoutCardHeader = () => (
  <div className="account-history__header--cashout-pending">
    <span className="account-history__pending-icon">
      <span className="bvs-icon" />
    </span>
    <div className="account-history__cashout-text">
      <strong>{t('javascript.cashout.settled.pending_payout.title')}</strong>
      <span>{t('javascript.cashout.settled.pending_payout.subtitle')}</span>
    </div>
  </div>
);

export default PendingPayoutCardHeader;
