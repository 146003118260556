import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import { format } from 'bv-helpers/number';
import { timeText, dateText } from 'AccountHistory/helpers/date_text';
import SpinListContainer from '../spin_list/spin_list_container';

const GameItem = ({
  toggleExpand,
  expand,
  gameName,
  dateCreated,
  id,
  profitLoss,
  renderLegs,
}) => (
  <div className="bvs-card account-history__container account-history__clickable">
    <div onClick={toggleExpand}>
      <div className="account-history__dropdown-wrapper">
        <Icon
          className="account-history__dropdown"
          id={`arrow-solid-circle-${expand ? 'up' : 'down'}`}
        />
        <span className="account-history__title">{gameName}</span>
      </div>

      <div className="flex title-row">
        <div className="flex--grow">
          {timeText(dateCreated)}
          ,
          {' '}
          {dateText(dateCreated)}
        </div>
        <div className="right-align">{t('javascript.account.my_bets.casino.game_pal')}</div>
      </div>
      <div className="flex">
        <div className="flex--grow">
          {t('javascript.account.my_bets.casino.id')}
          :
          {' '}
          {id}
        </div>
        <div className="right-align">
          <strong dangerouslySetInnerHTML={{ __html: sanitize(format(profitLoss)) }} />
        </div>
      </div>
    </div>

    {renderLegs
        && (
        <SpinListContainer
          expand={expand}
          id={id}
        />
        )}
  </div>
);

GameItem.propTypes = {
  expand: PropTypes.bool.isRequired,
  renderLegs: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  gameName: PropTypes.string.isRequired,
  dateCreated: PropTypes.number.isRequired,
  profitLoss: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default GameItem;
