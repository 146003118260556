import PropTypes from 'prop-types';

import { t } from 'bv-i18n';
import { timeText, dateText } from 'AccountHistory/helpers/date_text';

const BetDate = ({ betReference, date }) => (
  <div className="account-history__created-date">
    <div>
      {t('javascript.account.history.bet_date')}
      :
      {' '}
      {timeText(date)}
      ,
      {' '}
      {dateText(date)}
    </div>
    <div>
      {t('javascript.account.banking.transaction_id')}
      :
      {' '}
      {betReference}
    </div>
  </div>
);

BetDate.propTypes = {
  betReference: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default BetDate;
