import PropTypes from 'prop-types';
import { Spinner, SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import { reportType } from 'AccountHistory/helpers/export';
import TransactionsItemContainer from '../transactions_item/transactions_item_container';
import ExportButton from '../../export/export_button';

const TransactionsList = ({
  loading,
  error,
  bets,
  ...props
}) => {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <i>{t('javascript.account.errors.generic_error')}</i>;
  }

  if (bets && bets.length) {
    return (
      <div>
        {props.groups.map((group, index) => (
          <div className="bvs-cards-group" ref={props.betListRef} key={group}>
            <div className="account-history__group-wrapper">
              <div className="account-history__group-title--with-card">{group}</div>
              {index === 0 && <ExportButton type={reportType.transactions} />}
            </div>
            <div className="bvs-card account-history__bvs-card account-history__bvs-card--small-padding account-history__bvs-card--clickable">
              {bets.filter((f) => f.date === group)
                .map((m) => m.bets.map((bet) => <TransactionsItemContainer {...bet} />))}
            </div>
          </div>
        ))}
        {props.loadingMore && (
          <div className="account-history__spinner-wrapper">
            <SmallSpinner />
          </div>
        )}
      </div>
    );
  }
  return <div className="bvs-modal-section__empty">{t('javascript.account.errors.no_transactions')}</div>;
};

TransactionsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  groups: PropTypes.node.isRequired,
  bets: PropTypes.instanceOf(Array).isRequired,
  betListRef: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
};

export default TransactionsList;
