import PropTypes from 'prop-types';
import { SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import SpinItemContainer from '../spin_item/spin_item_container';
import LoadMore from './load_more';

const SpinList = ({
  expand,
  ...props
}) => {
  if (expand) {
    if (props.loading) {
      return <SmallSpinner />;
    }

    if (props.data && props.data.length) {
      return (
        <div className="account-history__wrapper">
          <div>
            {props.data.map((leg) => (
              <SpinItemContainer
                id={props.id}
                {...leg}
              />
            ))}
          </div>
          <LoadMore
            handleClick={props.handleClick}
            loadMore={props.loadMore}
            loadingMore={props.loadingMore}
          />
        </div>
      );
    }

    return (
      <div className="account-history__wrapper">
        <div className="account-history__container--error">
          {t('javascript.account.my_bets.casino.error')}
        </div>
      </div>
    );
  }

  return null;
};

SpinList.propTypes = {
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  expand: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  loadMore: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SpinList;
