import App from './components/app_redux';
import appReducer from './ducks/app';
import betsReducer from './ducks/bets';
import legsReducer from './ducks/legs';

const { addReducers } = window.reduxState;

addReducers({
  accountHistoryApp: appReducer,
  accountHistoryBets: betsReducer,
  accountHistoryLegs: legsReducer,
});

export default App;

// TODO: Issues found when migrating:
// - a elements used instead of Link, use Link so linkLocalizer is not needed
// - Using React lifecycle instead of Hooks, code can be way simpler
// - Some components are almost identical
// - Using ModalHelper, just use state to show modal
// - Too long components files containing more than 1 component in the same file
