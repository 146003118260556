export const formMap = {
  CreditDebitCards: 'CreditDebitCardsPaymentMethod',
  CreditDebitCardBVT: 'CreditDebitCardsPaymentMethod',
  BankcardBVT: 'CreditDebitCardsPaymentMethod',
  PaymentInsideCUPBVT: 'NetellerBVT',
  RapidPayWithdrawBVT: 'PaymentInsideCUPBVT',
  RapidPayWithdraw: 'PaymentInsideCUPBVT',
  NetellerBVT: 'NetellerBVT',
  Neteller: 'NetellerBVT',
  default: 'BasePaymentMethod',
};

const padStart = (string, targetLength, padString) => {
  if (string.length >= targetLength) {
    return string;
  }
  const maxLength = targetLength - string.length;
  let newPadString = padString;
  if (maxLength > newPadString.length) {
    newPadString += newPadString.repeat(maxLength / newPadString.length);
  }
  return newPadString.slice(0, maxLength) + string;
};

export const hidePan = (pan) => (pan ? padStart(pan.slice(-4), pan.length, '*') : '');
