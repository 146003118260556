import PropTypes from 'prop-types';
import { Spinner, SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import { dateTextShort } from 'AccountHistory/helpers/date_text';
import GameItemContainer from '../game_item/game_item_container';
import ExportButton from '../../export/export_button';
import { reportType } from '../../../helpers/export';

const GameList = ({
  loading,
  error,
  bets,
  ...props
}) => {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p className="account-history__empty error">{t('javascript.account.errors.generic_error')}</p>;
  }

  if (bets && bets.length) {
    return (
      <div>
        {props.groups.map((group, index) => (
          <div className="bvs-cards-group" ref={props.betListRef} key={group}>
            <div className="account-history__group-wrapper">
              {group && <div className="account-history__group-title">{dateTextShort(group)}</div>}
              {index === 0 && <ExportButton type={reportType.casino} />}
            </div>
            {bets.filter((f) => f.date === group)
              .map((m) => m.bets.map((bet) => <GameItemContainer {...bet} />))}
          </div>
        ))}
        {props.loadingMore && (
          <div className="account-history__spinner-wrapper">
            <SmallSpinner />
          </div>
        )}
      </div>
    );
  }
  return <div className="bvs-modal-section__empty">{t('javascript.account.errors.no_transactions')}</div>;
};

GameList.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  groups: PropTypes.node.isRequired,
  bets: PropTypes.instanceOf(Array).isRequired,
  betListRef: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default GameList;
