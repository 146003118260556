import { max } from 'underscore';

const getAmount = (minAmount, amountParam, lastDeposit) => {
  const amount = !Number.isNaN(parseFloat(amountParam)) ? amountParam : lastDeposit;

  return max([
    minAmount,
    parseFloat(amount),
  ]);
};

export default getAmount;
