import { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFeature } from 'bv-hooks';
import { t } from 'bv-i18n';
import { Button, Icon } from 'bv-components';
import { getReport, getIsEnabled } from 'AccountHistory/selectors';
import { loadReportStatus, setReportInfo } from 'AccountHistory/ducks/app';
import { generateReport, signalReportDownload } from 'AccountHistory/api/app';
import { confirmStatus, reportStatus, reportType } from 'AccountHistory/helpers/export';
import ConfirmModal from './confirm_modal';

const ExportButton = ({
  type,
}) => {
  const [confirm, setConfirm] = useState(confirmStatus.NONE);

  const [featureEnabled] = useFeature('transaction_history_export');
  const isEnabled = useSelector(getIsEnabled);
  const report = useSelector((state) => getReport(state, type));

  const dispatch = useDispatch();

  const buttonLabel = useMemo(() => {
    if (report.status === reportStatus.GENERATED && !!report.url) {
      return t('transaction_history.export.download');
    }

    return t('transaction_history.export.generate');
  }, [report.status, report.url]);

  const handleClick = () => {
    if (report.status === reportStatus.GENERATED) {
      window.location.href = report.url;
      dispatch(setReportInfo({ type }));
      signalReportDownload(type, report.id);
    } else {
      setConfirm(confirmStatus.REQUIRED);
    }
  };

  const handleConfirm = () => {
    generateReport(type)
      .then((reportInfo) => {
        dispatch(setReportInfo({ type, status: reportStatus.REQUESTED, ...reportInfo }));
        setConfirm(confirmStatus.CONFIRMED);
      })
      .catch(() => setConfirm(confirmStatus.FAILED));
  };

  useEffect(() => {
    if (isEnabled && featureEnabled && !report.status) {
      dispatch(loadReportStatus(type));
    }
  }, [isEnabled, featureEnabled]);

  if (!isEnabled || !featureEnabled) return null;

  return (
    <div className="account-history__export-btn">
      <ConfirmModal
        onConfirm={handleConfirm}
        onClose={() => setConfirm(confirmStatus.NONE)}
        status={confirm}
      />
      <Button
        secondary
        inverse
        type="button-card"
        className="bvs-button-card"
        onClick={handleClick}
        disabled={report.status === reportStatus.REQUESTED}
      >
        {buttonLabel}
        <Icon className="is-download" />
      </Button>
    </div>
  );
};

ExportButton.propTypes = {
  type: PropTypes.oneOf(Object.values(reportType)).isRequired,
};

export default ExportButton;
