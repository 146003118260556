import PropTypes from 'prop-types';
import { timeTextDetailed, dateText } from 'AccountHistory/helpers/date_text';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const SpinItem = ({
  realStake = '',
  bonusStake = '',
  gametime,
  combinedCredit,
}) => (
  <div className="account-history__container">
    <div className="flex title-row">
      <div className="flex--grow">
        {timeTextDetailed(gametime)}
        ,
        {' '}
        {dateText(gametime)}
      </div>

      <div className="right-align">
        <div>{t('javascript.account.history.stake')}</div>
        <div>{t('javascript.account.my_bets.casino.spin_return')}</div>
      </div>
    </div>
    <div className="flex">
      <div className="flex--grow">
        <strong>
          {/* API sending 0 (integer) when zero, but formatted string when positive */}
          {realStake || ''}
          {realStake && bonusStake ? ' + ' : ''}
          {bonusStake || ''}
        </strong>
        {bonusStake ? <Icon className="account-history__bonus-funds" id="bonus-funds" big alternative /> : ''}
      </div>
      <div className="right-align">
        <strong>{combinedCredit}</strong>
      </div>
    </div>
  </div>
);

SpinItem.propTypes = {
  gametime: PropTypes.number.isRequired,
  realStake: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  bonusStake: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  combinedCredit: PropTypes.string.isRequired,
};

export default SpinItem;
