/* eslint react/button-has-type: off */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import getMenus from 'AccountHistory/helpers/nav';

const Nav = ({
  title, section, typeName, showSportsTab,
}) => {
  const { subMenus, subMenuItems } = getMenus(showSportsTab, section, title);
  const history = useHistory();

  const historyReplace = (url) => () => { history.replace(url); };

  return (
    <>
      {subMenus && subMenus.length > 1 && (
        <div className="myaccount__sub-menu">
          <ul className="site-main-nav">
            {subMenus.map((entry) => (
              <li className={`site-main-nav__item${section === entry.type ? ' selected' : ''}`}>
                <Button onClick={historyReplace(entry.url)} type="account-history-menu-button" noClass replace>
                  {t(`javascript.account.my_bets.${entry.name}.title`)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {subMenuItems && (
        <div className="myaccount__sub-menu-items">
          {subMenuItems.map((item) => (
            <Button
              onClick={() => { history.replace(item.url); }}
              noClass
              className={`${typeName === item.name ? ' active' : ''} bvs-button-chip is-small`}
            >
              {t(`javascript.account.my_bets.sports.${item.name}`)}
            </Button>
          ))}
        </div>
      )}
    </>
  );
};

Nav.propTypes = {
  title: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  showSportsTab: PropTypes.bool.isRequired,
  typeName: PropTypes.string.isRequired,
};

export default Nav;
