import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import { timeText, dateText } from 'AccountHistory/helpers/date_text';

const CashOutHistory = ({
  toggleExpand,
  expand,
  coHistory,
}) => (
  <div onClick={toggleExpand}>
    <div className="account-history__cash-out-history">
      <span className="account-history__cash-out-history--title bvs-link">{t('javascript.account.my_bets.sports.cash_out_history')}</span>
      <Icon id={`arrow-${expand ? 'up' : 'down'}`} />
    </div>
    {expand
        && (
        <div className="flex title-row">
          {coHistory.map((item) => (
            <div key={item}>
              <div className="flex--grow">
                {timeText(item.date)}
                ,
                {' '}
                {dateText(item.date)}
              </div>
              <div className="align-right">
                {t('javascript.account.my_bets.sports.cashed_out')}
                :
              </div>
              <div className="align-right">
                <strong dangerouslySetInnerHTML={{ __html: sanitize(item.value) }} />
              </div>
            </div>
          ))}
        </div>
        )}
  </div>
);

CashOutHistory.propTypes = {
  coHistory: PropTypes.instanceOf(Array).isRequired,
  expand: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
};

export default CashOutHistory;
