import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetBets, load, loadMore } from 'AccountHistory/ducks/bets';
import { resetLegs } from 'AccountHistory/ducks/legs';
import { scrollable } from 'AccountHistory/config';

const mapStateToProps = (state) => ({
  loading: state.accountHistoryBets.loading,
  error: state.accountHistoryBets.error,
  groups: state.accountHistoryBets.groups,
  bets: state.accountHistoryBets.bets,
  loadMore: state.accountHistoryBets.loadMore,
  loadingMore: state.accountHistoryBets.loadingMore,
  pageType: state.accountHistoryApp.pageType,
});

const mapDispatchToProps = (dispatch) => ({
  resetBets: () => dispatch(resetBets()),
  resetLegs: () => dispatch(resetLegs()),
  fetch: () => dispatch(load()),
  fetchMore: () => dispatch(loadMore()),
});

export default (WrappedComponent) => {
  class withFetchBets extends Component {
    componentDidMount() {
      const { fetch } = this.props;

      fetch();
      if (scrollable()) scrollable().addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
      const { resetBets: resetBetsProp, resetLegs: resetLegsProp } = this.props;

      resetBetsProp();
      resetLegsProp();
      if (scrollable()) scrollable().removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
      const { loadingMore, loadMore: shouldLoadMore, fetchMore } = this.props;

      if (!loadingMore && shouldLoadMore) {
        const lastBet = this.betList.lastChild;

        if (lastBet && lastBet.getBoundingClientRect().bottom < window.innerHeight) {
          fetchMore();
        }
      }
    };

    render() {
      return (
        <WrappedComponent
          betListRef={(c) => { this.betList = c; }}
          {...this.props}
        />
      );
    }
  }

  withFetchBets.propTypes = {
    fetch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func.isRequired,
    resetBets: PropTypes.func.isRequired,
    resetLegs: PropTypes.func.isRequired,
    loadMore: PropTypes.bool.isRequired,
    loadingMore: PropTypes.bool.isRequired,
  };

  return connect(mapStateToProps, mapDispatchToProps)(withFetchBets);
};
