import { accountNumber } from 'bv-helpers/session';
import { dataLayer } from 'bv-helpers';

const pushDepositEvent = (parameters, quickDeposit) => dataLayer.push({
  event: (parameters.first_time_transaction === 'true' ? 'evt_first_time_deposit' : 'evt_deposit'),
  account_number: accountNumber(),
  deposit_amount: parameters['psp_parameters.amount'],
  deposit_currency: parameters['psp_parameters.currency'],
  deposit_method: parameters.payment_solution_name,
  deposit_success: parameters.success === 'true',
  quick_deposit: quickDeposit,
  transaction_id: parameters.payment_transaction_id,
});

export default pushDepositEvent;
