import { hasCasino, hasSportsbook } from 'global-config';

const getSubMenusMap = (showSportsTab) => {
  const myBetsTabs = [
    showSportsTab && { url: '/bv_cashier/history/3', name: hasSportsbook ? 'sports' : 'lucky_dips', type: 'sports' },
    hasCasino && { url: '/bv_cashier/history/1', name: 'casino', type: 'casino' },
  ].filter(Boolean);

  if (hasCasino && !hasSportsbook && showSportsTab) {
    myBetsTabs.reverse();
  }

  return {
    game_history: myBetsTabs,
    my_bets: myBetsTabs,
  };
};

const subMenuItemsMap = {
  sports: [
    { url: '/bv_cashier/history/3', name: 'open' },
    { url: '/bv_cashier/history/4', name: 'settled' },
  ],
  lottery: [
    { url: '/bv_cashier/lottery/6', name: 'open' },
    { url: '/bv_cashier/lottery/7', name: 'settled' },
  ],
};

export default (showSportsTab, section, title) => {
  const subMenus = getSubMenusMap(showSportsTab)[title];

  return {
    subMenus,
    subMenuItems: subMenuItemsMap[section],
  };
};
