import { getJSON, postJSON, putJSON } from 'bv-fetch';

export const fetchOptions = (type) => (
  getJSON(`/api/transaction_history/${type}/options`)
);

export const fetchReportStatus = (type) => (
  getJSON(`/api/transaction_history/${type}/report`)
);

export const generateReport = (type) => (
  postJSON(`/api/transaction_history/${type}/generate_report`)
);

export const signalReportDownload = (type, reportId) => (
  putJSON(`/api/transaction_history/${type}/signal_download`, { reportId })
);

export const fetchLastBetPlacedDate = () => getJSON('/api/betting_stats/last_bet_placed')
  .catch(() => null);
