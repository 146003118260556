import { toCurrency } from 'bv-helpers/number';
import { t } from 'bv-i18n';

export const LOSS_LIMIT_FORM = {
  name: 'lossLimits',
  fields: {
    type: 'type',
    config: 'typeConfig',
    period: 'selectedPeriod',
    amount: 'amount',
  },
};

export const formatLimitAmount = (limit) => {
  if (limit.amount === null) {
    return limit.periodLabel;
  }

  return toCurrency(limit.amount, { decimalPlaces: 0 });
};

export const limitModals = {
  LOSS_LIMITS: 'lossLimits',
  DEPOSIT_LIMIT: 'depositLimit',
};

export const NO_LIMIT_PERIOD_ID = -1;

export const NO_LIMIT_PERIOD = {
  id: NO_LIMIT_PERIOD_ID,
  name: 'no_limit',
  label: t('account_form.period.no_limit'),
};

export const limitDisplay = {
  NONE: 'NONE',
  SUGGESTED: 'SUGGESTED',
  MANDATORY: 'MANDATORY',
};

export const availableForLicence = (licence) => (
  [limitDisplay.SUGGESTED, limitDisplay.MANDATORY].includes(licence?.configurationStatus)
);

export const mandatoryForLicence = (licence) => (
  limitDisplay.MANDATORY === licence?.configurationStatus
);
