import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';

const { ModalHelper } = window;

export const deleteConfirm = (confirmAction) => {
  const actions = [{ label: t('deposit_limit.messages.confirmation.confirm'), primary: true, onClick: confirmAction }];
  ModalHelper.showInfoMessage({
    title: t('javascript.payment_method_delete_confirmation_title'),
    message: t('javascript.payment_method_delete_confirmation_body'),
    iconClosable: true,
    actions,
  });
};

export const showFetchError = (message = '') => (
  ModalHelper.showDangerMessage({
    message: message || t('errors_something_wrong'),
    actions: [{ label: t('javascript.close'), danger: true, close: true }],
    dataLayer: {
      error_message: message || t('errors_something_wrong'),
      error_key: message || 'errors_something_wrong',
      error_code: 'E0x002',
    },
  })
);

export const showSuccessMessage = (message = '', onCloseCallback = null) => ModalHelper.showSuccessMessage({
  message: message || t('payment_method_update_successfully'),
  iconClosable: true,
  onClose: onCloseCallback,
});

export const showSuccessDepositMessage = (amount) => (
  ModalHelper.showSuccessMessage({
    title: t('quick_deposit.successful_deposit'),
    ...(amount ? {
      message: t('quick_deposit.success_message', { amount: toCurrency(amount) }),
    } : {}),
    actions: [
      {
        label: t('continue'), id: 'ok-btn', inverse: true, primary: true, close: true,
      },
    ],
  })
);
