import { paymentSolutionGrouping } from 'bv-configs';
import { DEPOSIT, WITHDRAW } from './main';

const { groupDeposit, groupWithdraw } = paymentSolutionGrouping;
const groupingSettings = {
  [DEPOSIT]: groupDeposit,
  [WITHDRAW]: groupWithdraw,
};

const shouldGroupMethods = (operation) => groupingSettings[operation];

export default shouldGroupMethods;
