import PropTypes from 'prop-types';

import { sanitize } from 'dompurify';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const CardHeader = ({
  icon, isBig, type, value,
}) => (
  <div className="account-history__header--cashout">
    <span className="account-history__cashout-icon">
      <Icon
        id={icon}
        big={isBig}
        type="account-history-header-icon"
      />
    </span>
    <div className="account-history__cashout-text">
      <strong>{t(`javascript.account.sportsheader.${type}.title`)}</strong>
      <span>
        {t(`javascript.account.sportsheader.${type}.description`)}
        {' '}
        <span dangerouslySetInnerHTML={{ __html: sanitize(value) }} />
      </span>
    </div>
  </div>
);

CardHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isBig: PropTypes.bool,
};

CardHeader.defaultProps = {
  isBig: false,
};

export default CardHeader;
