import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { timeText, dateText } from 'AccountHistory/helpers/date_text';
import { t } from 'bv-i18n';
import { format } from 'bv-helpers/number';
import Header from './header';

const LottoItem = ({
  pageType,
  settledDate,
  state,
  gameName,
  price,
  winnings,
  drawDate,
}) => (
  <div className="bvs-card account-history__container">
    {pageType === 'settled'
        && (
        <Header
          date={settledDate}
          state={state}
        />
        )}
    <div className="account-history__title">{gameName}</div>
    <div className="flex">
      <div className="flex--grow">{t('javascript.account.history.ticket_price')}</div>
      <div className="right-align">
        {pageType === 'settled'
          ? <span>{t('javascript.account.history.return')}</span>
          : <span>{t('javascript.account.history.event_date')}</span>}
      </div>
    </div>
    <div className="flex">
      <div className="flex--grow">
        <strong dangerouslySetInnerHTML={{ __html: sanitize(price) }} />
      </div>
      <div className="right-align">
        {pageType === 'settled'
          ? <strong dangerouslySetInnerHTML={{ __html: sanitize(format(winnings)) }} />
          : (
            <strong>
              {timeText(drawDate)}
              ,
              {' '}
              {dateText(drawDate)}
            </strong>
          )}
      </div>
    </div>
  </div>
);

LottoItem.propTypes = {
  pageType: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  drawDate: PropTypes.number.isRequired,
  winnings: PropTypes.string.isRequired,
  settledDate: PropTypes.number,
  state: PropTypes.string,
};

LottoItem.defaultProps = {
  settledDate: null,
  state: null,
};

export default LottoItem;
