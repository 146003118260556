import apiFetch from '../api/bets';

export const LOAD_INIT = 'accountHistory/bets/LOAD_INIT';
export const LOAD_SUCCESS = 'accountHistory/bets/LOAD_SUCCESS';
export const LOAD_FAILURE = 'accountHistory/bets/LOAD_FAILURE';

export const LOAD_MORE_INIT = 'accountHistory/bets/LOAD_MORE_INIT';
export const LOAD_MORE_FAILURE = 'accountHistory/bets/LOAD_MORE_FAILURE';

export const RESET = 'accountHistory/bets/RESET';

// ---------- INITIAL LOAD ---------- //

export const loadInit = () => ({
  type: LOAD_INIT,
});

export const loadSuccess = (data) => ({
  type: LOAD_SUCCESS,
  payload: data,
});

export const loadFailure = () => ({
  type: LOAD_FAILURE,
});

export const load = () => (dispatch, getState) => {
  const { accountHistoryApp, accountHistoryBets } = getState();

  dispatch(loadInit());

  return apiFetch(
    accountHistoryApp.pageType,
    accountHistoryApp.pageSection,
    accountHistoryBets.pageNumber,
  )
    .then((data) => dispatch(loadSuccess(data)))
    .catch(() => dispatch(loadFailure()));
};

// ---------- SECONDARY LOAD ---------- //

export const loadMoreInit = () => ({
  type: LOAD_MORE_INIT,
});

export const loadMoreFailure = () => ({
  type: LOAD_MORE_FAILURE,
});

export const loadMore = () => (dispatch, getState) => {
  const { accountHistoryApp, accountHistoryBets } = getState();

  dispatch(loadMoreInit());

  return apiFetch(
    accountHistoryApp.pageType,
    accountHistoryApp.pageSection,
    accountHistoryBets.pageNumber,
  )
    .then((data) => dispatch(loadSuccess(data)))
    .catch(() => dispatch(loadMoreFailure()));
};

// ---------- END OF LOAD ---------- //

export const resetBets = () => ({
  type: RESET,
});

export const initialState = {
  loading: false,
  loadingMore: false,
  error: false,
  groups: [],
  bets: [],
  pageNumber: 1,
  loadMore: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_INIT:
      return {
        ...state,
        loading: true,
      };

    case LOAD_MORE_INIT:
      return {
        ...state,
        loadingMore: true,
      };

    case LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        loadMoreBets: false,
      };

    case LOAD_MORE_FAILURE:
      return {
        ...state,
        loadingMore: false,
        loadMore: false,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        groups: [...state.groups,
          ...action.payload.groups.filter((f) => !state.groups.includes(f.date))
            .map((group) => group.date)],
        bets: [...state.bets, ...action.payload.groups],
        pageNumber: state.pageNumber + 1,
        loadMore: action.payload.load_more,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
};
