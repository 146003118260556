import PropTypes from 'prop-types';

import { t } from 'bv-i18n';
import { timeText, dateText } from 'AccountHistory/helpers/date_text';

const possibleResults = ['won', 'lost', 'void', 'push'];

const Header = ({ date, result }) => (
  <div className="account-history__header">
    <span>
      {timeText(date)}
      ,
      {' '}
      {dateText(date)}
    </span>
    {result && possibleResults.includes(result)
        && (
          <div className={`result-card__ribbon result-card__ribbon--${result}`}>
            <span>{t(`javascript.account.my_bets.sports.bet.${result}`)}</span>
          </div>
        )}
  </div>
);

Header.propTypes = {
  date: PropTypes.string.isRequired,
  result: PropTypes.string,
};

Header.defaultProps = {
  result: null,
};

export default Header;
