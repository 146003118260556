import { t, locale } from 'bv-i18n';

const dateToString = (date, opts = {}) => date.toLocaleDateString(locale(), opts);
const timeToString = (date, opts) => date.toLocaleTimeString(locale(), opts);

export const timeText = (dateUnixTime) => timeToString(new Date(dateUnixTime), {
  hour: 'numeric',
  minute: 'numeric',
});

export const timeTextDetailed = (dateUnixTime) => timeToString(new Date(dateUnixTime), {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
});

export const dateText = (dateUnixTime) => dateToString(new Date(dateUnixTime), {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});

export const dateTextShort = (stringDate) => dateToString(new Date(stringDate), {
  month: 'short',
  year: 'numeric',
});

export const dateTextDetailed = (dateUnixTime) => {
  const eventDate = new Date(dateUnixTime);
  const eventDateToString = dateToString(eventDate);

  const todayDate = dateToString(new Date());

  const newDate = new Date();
  newDate.setDate(newDate.getDate() - 1);
  const yesterdayDate = dateToString(newDate);

  if (eventDateToString === todayDate) {
    return t('javascript.account.date.today');
  } if (eventDateToString === yesterdayDate) {
    return t('javascript.account.date.yesterday');
  }

  return dateToString(eventDate, {
    weekday: 'long',
    day: 'numeric',
  });
};

export const daysRemaining = (dateUnixTime) => {
  const currentDate = new Date();
  const estimatedDate = new Date(dateUnixTime);
  return Math.floor((estimatedDate - currentDate) / 86400000);
};
