import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import { SmallSpinner } from 'bv-components';

const LoadMore = ({ handleClick, loadMore, loadingMore }) => {
  if (loadMore) {
    if (loadingMore) {
      return (
        <li className="account-history__spinner-wrapper">
          <SmallSpinner />
        </li>
      );
    }

    return (
      <div className="account-history__load-more" onClick={handleClick}>
        {t('javascript.account.my_bets.casino.load_more')}
      </div>
    );
  }

  return null;
};

LoadMore.propTypes = {
  handleClick: PropTypes.func.isRequired,
  loadMore: PropTypes.bool.isRequired,
  loadingMore: PropTypes.bool.isRequired,
};

export default LoadMore;
