import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showBetslip } from 'right-sidebar';
import { addMultiple } from 'bv-services/betslip-adapter';
import withToggle from 'AccountHistory/components/hocs/with_toggle';
import { modalBack } from 'bv-helpers/location';
import BetItem from './bet_item';

class BetItemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddingToBetslip: false,
    };
  }

  addToBetslip = (e) => {
    e.stopPropagation();

    const { legs, id } = this.props;
    this.setState({ isAddingToBetslip: true });
    addMultiple(legs[id].unsettledLegs)
      .then(() => {
        this.setState({ isAddingToBetslip: false });
        showBetslip();
        modalBack();
      })
      .catch(() => this.setState({ isAddingToBetslip: false }));
  };

  render() {
    const { pageType, legs, betType } = this.props;
    return (
      <BetItem
        isSettled={pageType === 'settled'}
        legs={legs}
        addToBetslip={this.addToBetslip}
        isManualBet={betType && betType.toLowerCase() === 'manual bet'}
        {...this.state}
        {...this.props}
      />
    );
  }
}

BetItemContainer.propTypes = {
  betType: PropTypes.string.isRequired,
  legs: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
  pageType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  pageType: state.accountHistoryApp.pageType,
  legs: state.accountHistoryLegs,
});

export default connect(mapStateToProps)(withToggle(BetItemContainer));
