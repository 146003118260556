import { linkLocalizer } from 'bv-helpers/locale';
import { locale } from 'bv-i18n';

const betsUrlList = (pageType, pageNumber) => ({
  sports: `bv_api/account_history/${pageType}/${pageNumber}/bets`,
  lottery: `bv_api/account_history/${pageType}/${pageNumber}/lottoland`,
  transactions: `bv_api/account_history/transactions/${pageNumber}/banking`,
  casino: `bv_api/account_history/casino/${pageNumber}/list`,
});

const legsUrlList = (pageType, pageNumber, id) => ({
  sports: `bv_api/account_history/${pageType}/bets/${id}`,
  casino: `bv_api/account_history/casino/${pageNumber}/show/${id}`,
});

export const getBetsUrl = (pageType, pageSection, pageNumber) => (
  betsUrlList(pageType, pageNumber)[pageSection]
);

export const getLegsUrl = (pageType, pageSection, pageNumber, id) => (
  legsUrlList(pageType, pageNumber, id)[pageSection]
);

export const getPreEventUrl = (sport, meeting, event) => (
  linkLocalizer(`/sports/${sport}/meetings/${meeting}/events/${event}`)
);

export const getPreEventNoMeetingUrl = (sport, event) => (
  linkLocalizer(`/sports/${sport}/events/${event}`)
);

export const getVirtualEventUrl = (sport, event) => (
  linkLocalizer(`/virtuals/${sport}/events/${event}`)
);

export const getDictUrl = (sport) => (
  `/bv_api/dictionary/${locale()}/${sport}`
);

export const getScoreboardsUrl = (sport, event) => (
  `/c/essentialscoreboard/${sport}/${event}`
);

let scrollableEl;

export const setScrollable = (scrollable) => {
  scrollableEl = scrollable;
};

export const scrollable = () => (
  scrollableEl && scrollableEl.current
);
