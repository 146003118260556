import apiFetch from '../api/legs';

export const LOAD_INIT = 'accountHistory/legs/LOAD_INIT';
export const LOAD_FAILURE = 'accountHistory/legs/LOAD_FAILURE';
export const LOAD_SUCCESS = 'accountHistory/legs/LOAD_SUCCESS';

export const LOAD_MORE_INIT = 'accountHistory/legs/LOAD_MORE_INIT';

export const RESET_LEGS = 'accountHistory/legs/RESET_LEGS';

// ---------- INITIAL LOAD ---------- //

export const loadInit = (id) => ({
  type: LOAD_INIT,
  id,
});

export const loadSuccess = (data, id) => ({
  type: LOAD_SUCCESS,
  payload: data,
  id,
});

export const loadFailure = (id) => ({
  type: LOAD_FAILURE,
  id,
});

export const load = (id) => (dispatch, getState) => {
  const { accountHistoryApp } = getState();

  dispatch(loadInit(id));

  return apiFetch(
    accountHistoryApp.pageType,
    accountHistoryApp.pageSection,
    getState().accountHistoryLegs[id].pageNumber,
    id,
  )
    .then((data) => dispatch(loadSuccess(data, id)))
    .catch(() => dispatch(loadFailure(id)));
};

// ---------- SECONDARY LOAD ---------- //

export const loadMoreInit = (id) => ({
  type: LOAD_MORE_INIT,
  id,
});

export const loadMore = (id) => (dispatch, getState) => {
  const { accountHistoryApp } = getState();

  dispatch(loadMoreInit(id));

  return apiFetch(
    accountHistoryApp.pageType,
    accountHistoryApp.pageSection,
    getState().accountHistoryLegs[id].pageNumber,
    id,
  )
    .then((data) => dispatch(loadSuccess(data, id)))
    .catch(() => dispatch(loadFailure()));
};

// ---------- END OF LOAD ---------- //

export const resetLegs = () => ({
  type: RESET_LEGS,
});

export const initialState = {
  // ...
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOAD_INIT:
      return {
        ...state,
        [action.id]: {
          loading: true,
          loadingMore: false,
          loadMore: true,
          data: [],
          coHistory: null,
          pageNumber: 1,
          createdDate: null,
          error: false,
          unsettledLegs: [],
        },
      };

    case LOAD_MORE_INIT:
      return {
        ...state,
        [action.id]: {
          ...state[action.id], loadingMore: true,
        },
      };

    case LOAD_FAILURE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false,
          loadingMore: false,
          loadMore: false,
          error: true,
        },
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          loading: false,
          loadingMore: false,
          loadMore: action.payload.load_more,
          data: state[action.id].data.concat(action.payload.legs),
          coHistory: action.payload.coHistory,
          pageNumber: state[action.id].pageNumber + 1,
          createdDate: action.payload.createdDate,
          unsettledLegs: action.payload.legs
            .filter((leg) => !leg.result || leg.result === 'none')
            .map((leg) => leg.outcomeId),
        },
      };

    case RESET_LEGS:
      return initialState;

    default:
      return state;
  }
};
