import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { defaultAssetPath } from 'bv';
import { Icon } from 'bv-components';
import { timeText, dateText } from 'AccountHistory/helpers/date_text';
import { t } from 'bv-i18n';
import { format } from 'bv-helpers/number';

const TransactionsItem = ({
  status,
  debit,
  toggleExpand,
  createdDate,
  expand,
  credit,
  logoPaymentMethodUrl,
  transactionId,
}) => {
  const parsedStatus = status === 'completed' && debit ? 'psp_pending' : status;

  return (
    <div className="account-history__container">
      <div onClick={toggleExpand}>
        <div className="account-history__header-wrapper--small">
          <span>
            {timeText(createdDate)}
            ,
            {' '}
            {dateText(createdDate)}
          </span>
        </div>
        <div className="flex">
          <div className="account-history-dropdown">
            <Icon id={`arrow-solid-circle-${expand ? 'up' : 'down'}`} />
          </div>
          <div className="flex--grow">
            <div
              className="account-history__title"
              dangerouslySetInnerHTML={{ __html: sanitize(format(credit || debit)) }}
            />
            <div><strong>{t(`javascript.account.overview.${credit ? 'deposit' : 'withdrawal'}`)}</strong></div>
          </div>
          <div>
            <div
              className={`result-card__ribbon result-card__ribbon--centered result-card__ribbon--with-border
              result-card__ribbon--with-border-${parsedStatus}`}
            >
              <span>{t(`javascript.account.banking.status.${parsedStatus}`)}</span>
              <div />
            </div>
          </div>
        </div>
      </div>

      {expand
        && (
        <div className="account-history__extra-details">
          <div className="account-history__logo-wrapper" style={{ backgroundImage: `url('${defaultAssetPath(logoPaymentMethodUrl)}')` }} />
          <span>
            {t('javascript.account.banking.transaction_id')}
            :
            {' '}
          </span>
          <span>{transactionId}</span>
        </div>
        )}
    </div>
  );
};

TransactionsItem.propTypes = {
  toggleExpand: PropTypes.func.isRequired,
  createdDate: PropTypes.number.isRequired,
  expand: PropTypes.bool.isRequired,
  credit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  debit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  status: PropTypes.string.isRequired,
  logoPaymentMethodUrl: PropTypes.string.isRequired,
  transactionId: PropTypes.number.isRequired,
};

export default TransactionsItem;
