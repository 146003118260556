import { t } from 'bv-i18n';
import { refresh as refreshBalance } from 'bv-services/account-balance';
import { showSuccessMessage, showSuccessDepositMessage, showFetchError } from './dialogs';
import pushDepositEvent from './push_deposit_event';

const { VCSTATE } = window;

const handleCashierResponse = (e, fetchDepositLimit, setResult) => {
  const { data: { source, parameters } } = e;
  if (source === 'transactionResult') {
    refreshBalance();

    if (parameters.deposit === 'true') {
      pushDepositEvent(parameters, false);
    }

    if (parameters.success === 'true') {
      if (fetchDepositLimit) fetchDepositLimit();

      if (VCSTATE.CASHIER_POKER_ERRORS.includes(parameters.error_code)) {
        showSuccessMessage(t('cashier_success_message_to_main_wallet'));
        showFetchError(parameters.error_message);
        return;
      }
    }

    if (setResult) {
      setResult(parameters);
    } else if (parameters.success === 'true') {
      if (parameters.deposit === 'true') {
        showSuccessDepositMessage(parameters['psp_parameters.amount']);
      } else {
        showSuccessMessage(t('cashier_withdraw_success_message'));
      }
    } else {
      showFetchError(parameters.error_message);
    }
  }
};

export default handleCashierResponse;
