import PropTypes from 'prop-types';
import { Spinner, SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import { reportType } from 'AccountHistory/helpers/export';
import { dateTextShort } from 'AccountHistory/helpers/date_text';
import BetItemContainer from '../bet_item/bet_item_container';
import ExportButton from '../../export/export_button';

const BetList = ({
  loading,
  error,
  bets,
  groups,
  betListRef,
  loadingMore,
  pageType,
}) => {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p className="account-history__empty error">{t('javascript.account.errors.generic_error')}</p>;
  }

  if (bets && bets.length) {
    return (
      <>
        {groups.map((group, index) => (
          <div className="bvs-cards-group" ref={betListRef} key={group}>
            <div className="account-history__group-wrapper">
              {group && <div className="account-history__group-title">{dateTextShort(group)}</div>}
              {index === 0 && <ExportButton type={reportType.sports} />}
            </div>
            {bets.filter((f) => f.date === group)
              .map((m) => m.bets.map((bet) => <BetItemContainer {...bet} />))}
          </div>
        ))}
        {loadingMore && (
          <div className="account-history__spinner-wrapper">
            <SmallSpinner />
          </div>
        )}
      </>
    );
  }
  return <div className="bvs-modal-section__empty">{t(`javascript.account.errors.no_${pageType}_bets`)}</div>;
};

BetList.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  groups: PropTypes.node.isRequired,
  bets: PropTypes.instanceOf(Array).isRequired,
  betListRef: PropTypes.func.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default BetList;
