import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { format } from 'bv-helpers/number';

const EnhancedReturnsView = ({ isSettled, grossNormalReturns, grossEnhancedReturns }) => (
  <div className="flex">
    <div className="flex--grow" />
    <div className="align-right">
      <div className="enhanced-odds__returns">
        <span className="enhanced-odds">
          <span className="enhanced-odds__label">
            {isSettled
              ? <span>{t('normal_returns')}</span>
              : <span>{t('normal_potential_returns')}</span>}
          </span>
          <span className="enhanced-odds__previous-odds">{format(grossNormalReturns)}</span>
        </span>
        <span className="outcome-box">
          <span className="outcome-enhanced-odds">
            <span className="bvs-event-icon is-bet-boost" />
            {isSettled
              ? <span>{t('enhanced_returns')}</span>
              : <span>{t('enhanced_potential_returns')}</span>}
          </span>
          <span className="outcome-previous-odds">{format(grossEnhancedReturns)}</span>
        </span>
      </div>
    </div>
  </div>
);

EnhancedReturnsView.propTypes = {
  isSettled: PropTypes.bool.isRequired,
  grossNormalReturns: PropTypes.isRequired,
  grossEnhancedReturns: PropTypes.isRequired,
};

export default EnhancedReturnsView;
