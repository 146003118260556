import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ModalWindow, Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import { CashierHeaderMenu } from 'CashierCommon/components';
import { hasSportsbook } from 'global-config';
import { fetchLastBetPlacedDate } from '../api/app';
import App from './app';
import Nav from './nav';
import { setScrollable } from '../config';
import getType from '../helpers/get_type';

const typeToTitleMap = {
  1: hasSportsbook ? 'my_bets' : 'game_history',
  3: 'my_bets',
  4: 'my_bets',
  5: 'banking_history',
  6: 'lottery',
  7: 'lottery',
};

const typeToSectionMap = {
  1: 'casino',
  3: 'sports',
  4: 'sports',
  5: 'transactions',
  6: 'lottery',
  7: 'lottery',
};

const typeToTypeNameMap = {
  1: 'casino',
  3: 'open',
  4: 'settled',
  5: 'transactions',
  6: 'open',
  7: 'settled',
};

const AppContainer = ({
  setConfig, resetConfig, loadInit, loadOptions,
}) => {
  const [{ showSportsTab, loading }, setShowSportsTab] = useState({
    showSportsTab: hasSportsbook,
    loading: !hasSportsbook,
  });
  const [showMenu, setShowMenu] = useState(false);
  const type = parseInt(useParams().type, 10) || getType();
  const title = typeToTitleMap[type];
  const section = typeToSectionMap[type];
  const typeName = typeToTypeNameMap[type];

  const scrollableRef = useRef();

  useEffect(() => {
    loadInit();
    setConfig(section, typeName);
    loadOptions(section);
  }, [type]);

  useEffect(() => {
    if (!hasSportsbook) {
      // show the sports history for casino only brands if the user has
      // placed any bet (in lucky dip market)
      fetchLastBetPlacedDate().then((lastBetPlacedDate) => {
        setShowSportsTab({ showSportsTab: !!lastBetPlacedDate, loading: false });
      });
    }
  }, []);

  // TODO: Use context + useContext when re-built
  useEffect(() => {
    setScrollable(scrollableRef);

    return resetConfig;
  }, []);

  return (
    <ModalWindow
      title={t(`javascript.account.overview.${title}`)}
      className="acc-history-modal"
      scrollableRef={scrollableRef}
      dots={title === 'banking_history'}
      onDotsClick={() => setShowMenu(true)}
    >
      {loading ? <Spinner /> : (
        <>
          <Nav title={title} section={section} typeName={typeName} showSportsTab={showSportsTab} />
          <App />
          {showMenu && (
            <CashierHeaderMenu onCloseClick={() => setShowMenu(false)} />
          )}
        </>
      )}
    </ModalWindow>
  );
};

AppContainer.propTypes = {
  setConfig: PropTypes.func.isRequired,
  resetConfig: PropTypes.func.isRequired,
  loadInit: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
};

export default AppContainer;
