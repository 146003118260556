import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { registerHandler, unregisterHandler } from 'event-bus';

import { getJSON } from 'bv-fetch';
import { getDictUrl, getScoreboardsUrl } from 'AccountHistory/config';
import withToggle from 'AccountHistory/components/hocs/with_toggle';
import LegItem from './leg_item';

class LegItemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrA: null,
      scrB: null,
      code: null,
      dict: null,
      showScoreboards: false,
    };
  }

  componentDidMount() {
    const { inplay, sportId, eventId } = this.props;

    if (inplay) {
      getJSON(getDictUrl(sportId))
        .then((data) => (
          this.setState({ dict: data })
        ))
        .catch(() => {});

      registerHandler(
        getScoreboardsUrl(sportId, eventId),
        this.updateScoreboards,
      );
    }
  }

  componentWillUnmount() {
    const { inplay, sportId, eventId } = this.props;

    if (inplay) {
      unregisterHandler(
        getScoreboardsUrl(sportId, eventId),
        this.updateScoreboards,
      );
    }
  }

  updateScoreboards = (err, { body: { scrA, scrB, code } }) => {
    if (scrA != null && scrB != null && code != null) {
      this.setState({
        scrA,
        scrB,
        code,
        showScoreboards: true,
      });
    }
  };

  render() {
    return <LegItem {...this.state} {...this.props} />;
  }
}

LegItemContainer.propTypes = {
  inplay: PropTypes.bool.isRequired,
  sportId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  pageType: state.accountHistoryApp.pageType,
});

export default connect(mapStateToProps)(withToggle(LegItemContainer));
