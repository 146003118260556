import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { load, loadMore } from 'AccountHistory/ducks/legs';
import SpinList from './spin_list';

class SpinListContainer extends Component {
  componentDidMount() {
    const { fetch, id } = this.props;

    fetch(id);
  }

  handleClick = () => {
    const { fetchMore, id } = this.props;

    fetchMore(id);
  };

  render() {
    return <SpinList {...this.props} handleClick={this.handleClick} />;
  }
}

SpinListContainer.propTypes = {
  fetch: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => (
  state.accountHistoryLegs[ownProps.id]
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetch: () => dispatch(load(ownProps.id)),
  fetchMore: () => dispatch(loadMore(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpinListContainer);
