import { setConfig, resetConfig, loadOptions } from 'AccountHistory/ducks/app';
import { loadInit } from 'AccountHistory/ducks/bets';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import AppContainer from './app_container';

const mapDispatchToProps = (dispatch) => ({
  setConfig: compose(dispatch, setConfig),
  resetConfig: compose(dispatch, resetConfig),
  loadInit: compose(dispatch, loadInit),
  loadOptions: compose(dispatch, loadOptions),
});

export default connect(null, mapDispatchToProps)(AppContainer);

// TODO: Redux should not be needed for the config, the config is basically coming from the route
// We want an unique source of truth
// Not in the scope of the migration
