import PropTypes from 'prop-types';
import { SmallSpinner } from 'bv-components';
import { t } from 'bv-i18n';
import LegItemContainer from '../leg_item/leg_item_container';
import CashOutHistoryContainer from '../cash_out_history/cash_out_history_container';
import BetDate from './bet_date';

const LegList = ({
  betReference,
  createdDate,
  fallbackCreatedDate,
  expand,
  ...props
}) => {
  const date = createdDate || fallbackCreatedDate;

  if (expand) {
    if (props.loading) {
      return <SmallSpinner />;
    }

    if (props.data && props.data.length) {
      return (
        <div>
          {props.coHistory && props.coHistory.length > 0
            && <CashOutHistoryContainer coHistory={props.coHistory} />}
          <div className="account-history__wrapper">
            <div>
              {props.data.map((leg, index) => (
                <LegItemContainer
                  id={props.id}
                  index={index}
                  {...leg}
                />
              ))}
            </div>
            <BetDate
              date={date}
              betReference={betReference}
            />
          </div>
        </div>
      );
    }

    if ((props.error || !props.data.length) && props.description) {
      return (
        <div className="account-history__wrapper">
          <div className="account-history__container">{props.description}</div>
          <BetDate
            date={date}
            betReference={betReference}
          />
        </div>
      );
    }

    return <i>{t('javascript.account.errors.generic_error')}</i>;
  }

  return null;
};

LegList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  coHistory: PropTypes.instanceOf(Array).isRequired,
  id: PropTypes.number.isRequired,
  expand: PropTypes.bool.isRequired,
  createdDate: PropTypes.number,
  fallbackCreatedDate: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  betReference: PropTypes.string.isRequired,
};

LegList.defaultProps = {
  loading: true,
  createdDate: null,
  // since each Leg is a new different state (using their unique ID)
  // and can't have an initialstate on the reducer
  // loading needs a default value to avoid being undefined when this view is rendered
};

export default LegList;
