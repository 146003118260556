import { Component } from 'react';

export default (WrappedComponent) => (class withToggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderLegs: false,
      expand: false,
    };

    this.toggleExpand = this.toggleExpand.bind(this);
  }

  toggleExpand() {
    this.setState((prevState) => ({
      renderLegs: true,
      expand: !prevState.expand,
    }));
  }

  render() {
    const { expand, renderLegs } = this.state;

    return (
      <WrappedComponent
        expand={expand}
        renderLegs={renderLegs}
        toggleExpand={this.toggleExpand}
        {...this.props}
      />
    );
  }
});
