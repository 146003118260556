const extractParamsFromUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get('cashier_callback')) {
    return {
      data: {
        source: 'transactionResult',
        parameters: [...queryParams.entries()]
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      },
    };
  }
  return null;
};

export default extractParamsFromUrl;
