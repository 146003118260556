import { Component } from 'react';
import withFetchBets from 'AccountHistory/components/hocs/with_fetch_bets';
import GameList from './game_list';

class GameListContainer extends Component {
  componentDidMount() {
    localStorage.setItem('my_bets_last', '1');
  }

  render() {
    return <GameList {...this.props} />;
  }
}

export default withFetchBets(GameListContainer);
