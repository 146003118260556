export const reportStatus = {
  NONE: 'NONE',
  REQUESTED: 'REQUESTED',
  FAILED: 'FAILED',
  GENERATED: 'GENERATED',
};

export const confirmStatus = {
  NONE: 'NONE',
  REQUIRED: 'REQUIRED',
  CONFIRMED: 'CONFIRMED',
  FAILED: 'FAILED',
};

export const reportType = {
  sports: 'SPORTSBOOK',
  transactions: 'PAYMENTS',
  casino: 'GAMING',
};
