import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import { timeText, dateText } from 'AccountHistory/helpers/date_text';

const Header = ({ date, state: initialState }) => {
  const state = initialState === 'void' ? 'lost' : initialState;

  return (
    <div className="account-history__header">
      <span>
        {timeText(date)}
        ,
        {' '}
        {dateText(date)}
      </span>
      <div className={`result-card__ribbon result-card__ribbon--${state}`}>
        <span>{t(`javascript.account.my_bets.sports.bet.${state}`)}</span>
        <div />
      </div>
    </div>
  );
};

Header.propTypes = {
  date: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default Header;
