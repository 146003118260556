import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import { timeText, dateText } from 'AccountHistory/helpers/date_text';

const ExtraDetails = ({ details }) => {
  const description = (key) => {
    if (key === 'event_date') {
      return `${timeText(details[key])}, ${dateText(details[key])}`;
    } if (details === 'ew') {
      return t('javascript.account.my_bets.sports.details.yes');
    }
    return details[key];
  };

  return (
    (
      <div className="account-history__extra-details">
        <div className="account-history__flex">
          {Object.keys(details).map((key) => details[key]
            && (
              <div className="account-history__row">
                <span className="right-align">
                  {t(`javascript.account.my_bets.sports.details.${key}`)}
                  :
                </span>
                <span className="account-history__extra-details--description">{description(key)}</span>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

ExtraDetails.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
};

export default ExtraDetails;
