import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const separator = ' - ';

const Handicaps = ({
  handicaps: {
    handicap1, handicap2, result1, result2,
  },
}) => (
  <div className="account-history__handicaps">
    <div>
      {handicap1}
      {separator}
      {t(`javascript.account.my_bets.sports.bet.${result1.toLowerCase()}`)}
    </div>
    <div>
      {handicap2}
      {separator}
      {t(`javascript.account.my_bets.sports.bet.${result2.toLowerCase()}`)}
    </div>
  </div>
);

Handicaps.propTypes = {
  handicaps: PropTypes.instanceOf(Object).isRequired,
};

export default Handicaps;
