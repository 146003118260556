import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { hasSportsbook } from 'global-config';
import {
  getPreEventNoMeetingUrl,
  getPreEventUrl,
  getVirtualEventUrl,
} from 'AccountHistory/config';
import isVirtual from 'Sportsbook/helpers/is_virtual';

const Scoreboards = ({
  pageType,
  eventPathId,
  eventId,
  meetingId,
  showScoreboards,
  isOpen,
  ...props
}) => {
  let url = '';

  if (pageType === 'open' || pageType === 'settled') {
    if (isVirtual(eventPathId)) {
      url = getVirtualEventUrl(eventPathId, eventId);
    } else if (eventPathId === 1000) { // greyhounds
      url = getPreEventNoMeetingUrl(eventPathId, eventId);
    } else {
      url = getPreEventUrl(eventPathId, meetingId, eventId);
    }
  }

  if (showScoreboards) {
    return (
      <div className="account-history__scoreboards-wrapper">
        <a className="bvs-button is-primary account-history__scoreboards-link" href={url}>
          {props.scrA}
          {' '}
          -
          {props.scrB}
          {props.dict && (
          <span>
            ,
            {props.dict[props.code]}
          </span>
          )}
        </a>
      </div>
    );
  }

  if (isOpen && hasSportsbook) {
    return (
      <div className="account-history__scoreboards-wrapper">
        <a className="bvs-button is-primary account-history__scoreboards-link" href={url}>
          {t('javascript.account.my_bets.sports.go_to_event')}
        </a>
      </div>
    );
  }

  return null;
};

Scoreboards.propTypes = {
  pageType: PropTypes.string.isRequired,
  inplay: PropTypes.bool.isRequired,
  sportId: PropTypes.number.isRequired,
  eventPathId: PropTypes.number.isRequired,
  meetingId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  showScoreboards: PropTypes.bool.isRequired,
  scrA: PropTypes.number.isRequired,
  scrB: PropTypes.number.isRequired,
  dict: PropTypes.instanceOf(Object).isRequired,
  code: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Scoreboards;
