import { Component } from 'react';
import withFetchBets from 'AccountHistory/components/hocs/with_fetch_bets';
import BetList from './bet_list';

class BetListContainer extends Component {
  componentDidMount() {
    localStorage.setItem('my_bets_last', '3');
  }

  render() {
    return <BetList {...this.props} />;
  }
}

export default withFetchBets(BetListContainer);
