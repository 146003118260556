import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BetListContainer from './bets/bet_list/bet_list_container';
import LottoListContainer from './lotto/lotto_list/lotto_list_container';
import GameListContainer from './casino/game_list/game_list_container';
import TransactionsListContainer from './banking/transactions_list/transactions_list_container';

const sectionToComponentMap = {
  transactions: TransactionsListContainer,
  casino: GameListContainer,
  lottery: LottoListContainer,
  sports: BetListContainer,
};

const App = ({ pageSection, pageType }) => {
  const Component = sectionToComponentMap[pageSection];

  return (
    <>
      {/* Bad designed app, not ready to receive new props
      * Fetch is done only when component mounted, to be redone
      * TODO: Rebuild-me
      */}
      {Component && <Component key={`${pageSection}-${pageType}`} />}
    </>
  );
};

App.propTypes = {
  pageSection: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  pageSection: state.accountHistoryApp.pageSection,
  pageType: state.accountHistoryApp.pageType,
});

App.displayName = 'AccountHistory';

export default connect(mapStateToProps)(App);
